/* eslint-disable react/prop-types */
const chartItemById = {
  'pm2.5': [
    {
      color: '#00FF9D',
    },
    {
      color: '#2BFD93',
    },
    {
      color: '#55FB89',
      value: 10,
    },
    {
      color: '#80F97F',
    },
    {
      color: '#AAF775',
      value: 15,
    },
    {
      color: '#D4F56B',
    },
    {
      color: '#FFF361',
      value: 25,
    },
    {
      color: '#FFD45B',
    },
    {
      color: '#FFB654',
      value: 37.5,
    },
    {
      color: '#FF974E',
    },
    {
      color: '#FF7848',
      value: 50,
    },
    {
      color: '#FF5A41',
    },
    {
      color: '#FF3B3B',
      value: 75,
    },
  ],
  pm10: [
    {
      color: '#00FF9D',
    },
    {
      color: '#2BFD93',
    },
    {
      color: '#55FB89',
      value: 50,
    },
    {
      color: '#80F97F',
    },
    {
      color: '#AAF775',
      value: 75,
    },
    {
      color: '#D4F56B',
    },
    {
      color: '#FFF361',
      value: 100,
    },
    {
      color: '#FFD45B',
    },
    {
      color: '#FFB654',
      value: 150,
    },
    {
      color: '#FF974E',
    },
    {
      color: '#FF7848',
      value: 225,
    },
    {
      color: '#FF5A41',
    },
    {
      color: '#FF3B3B',
      value: 340,
    },
  ],
  db: [
    {
      color: '#00FF9D',
    },
    {
      color: '#2BFD93',
    },
    {
      color: '#55FB89',
      value: 60,
    },
    {
      color: '#80F97F',
    },
    {
      color: '#AAF775',
      value: 75,
    },
    {
      color: '#D4F56B',
    },
    {
      color: '#FFF361',
      value: 85,
    },
    {
      color: '#FFD45B',
    },
    {
      color: '#FFB654',
      value: 100,
    },
    {
      color: '#FF974E',
    },
    {
      color: '#FF7848',
      value: 120,
    },
    {
      color: '#FF5A41',
    },
    {
      color: '#FF3B3B',
      value: 140,
    },
  ],
  uv: [
    {
      color: '#00FF9D',
    },
    {
      color: '#2BFD93',
    },
    {
      color: '#55FB89',
      value: 2240,
    },
    {
      color: '#80F97F',
    },
    {
      color: '#AAF775',
      value: 4482,
    },
    {
      color: '#D4F56B',
    },
    {
      color: '#FFF361',
      value: 5976,
    },
    {
      color: '#FFD45B',
    },
    {
      color: '#FFB654',
      value: 7096,
    },
    {
      color: '#FF974E',
    },
    {
      color: '#FF7848',
      value: 8216,
    },
    {
      color: '#FF5A41',
    },
    {
      color: '#FF3B3B',
      value: 10000,
    },
  ],
  voc: [
    {
      color: '#00FF9D',
    },
    {
      color: '#2BFD93',
    },
    {
      color: '#55FB89',
      value: 0.2,
    },
    {
      color: '#80F97F',
    },
    {
      color: '#AAF775',
      value: 3,
    },
    {
      color: '#D4F56B',
    },
    {
      color: '#FFF361',
      value: 10,
    },
    {
      color: '#FFD45B',
    },
    {
      color: '#FFB654',
      value: 20,
    },
    {
      color: '#FF974E',
    },
    {
      color: '#FF7848',
      value: 24,
    },
    {
      color: '#FF5A41',
    },
    {
      color: '#FF3B3B',
      value: 26,
    },
  ],
  co2: [
    {
      color: '#00FF9D',
    },
    {
      color: '#2BFD93',
    },
    {
      color: '#55FB89',
      value: 350,
    },
    {
      color: '#80F97F',
    },
    {
      color: '#AAF775',
      value: 400,
    },
    {
      color: '#D4F56B',
    },
    {
      color: '#FFF361',
      value: 1000,
    },
    {
      color: '#FFD45B',
    },
    {
      color: '#FFB654',
      value: 1500,
    },
    {
      color: '#FF974E',
    },
    {
      color: '#FF7848',
      value: 1999,
    },
    {
      color: '#FF5A41',
    },
    {
      color: '#FF3B3B',
      value: 3000,
    },
  ],
};
/*
const chartItems = [
  {
    color: '#00FF9D',
  },
  {
    color: '#2BFD93',
  },
  {
    color: '#55FB89',
    value: 25,
  },
  {
    color: '#80F97F',
  },
  {
    color: '#AAF775',
    value: 50,
  },
  {
    color: '#D4F56B',
  },
  {
    color: '#FFF361',
    value: 100,
  },
  {
    color: '#FFD45B',
  },
  {
    color: '#FFB654',
    value: 200,
  },
  {
    color: '#FF974E',
  },
  {
    color: '#FF7848',
    value: 300,
  },
  {
    color: '#FF5A41',
  },
  {
    color: '#FF3B3B',
    value: 400,
  },
];
*/
export default function MapLegend(props) {
  const { toggleValueDescription, selectedValue } = props;

  return (
    <div className="value-legend">
      <p style={{ letterSpacing: '3px' }}>Categorias del ICAire</p>
      <div style={{ display: 'flex', marginTop: '10px' }}>
        <div style={{
          textAlign: 'right', fontSize: '0.8em', paddingTop: '11px', fontWeight: 'bold',
        }}
        >
          0
        </div>
        {chartItemById[selectedValue.id].map((item) => (
          <div key={item.value} style={{ flexGrow: 1 }}>
            <div style={{ borderBottom: `solid 1px ${item.color}` }} />
            <div style={{
              textAlign: 'right', paddingTop: '10px', fontSize: '0.8em', fontWeight: 'bold',
            }}
            >
              {item.value}
            </div>
          </div>
        ))}
      </div>
      <button
        className={selectedValue.id !== 'none' ? 'map-legend-button' : 'opacity-0'}
        type="button"
        onClick={toggleValueDescription}
      >
        <span style={{ marginRight: '7px' }} className="map-legend-exclamation">!</span>
        Qué es esto?
      </button>
    </div>
  );
}
