import { useState } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
// import EchoVideo from '../assets/video/echo.mp4';
import VideoHuerta from '../assets/video/Huerta.mp4';

// const items = [
//   {
//     id: 1,
//     title: 'Toyota Hybrid Synergy Drive.',
//     subtitle: 'Introducción y liderazgo en tecnologías híbridas',
//     active: true,
//     content: '<div style="padding: 0px 20px;"><div>HOLA CHANGO</div><iframe id="videoAboutModal" width="100%" height="600" src="https://www.youtube.com/embed/bzCuN5SVMQ8"  frameborder="0" allowfullscreen></iframe><div>',
//   },
//   {
//     id: 2,
//     title: 'The Hy Project',
//     subtitle: '(Fast Company´s World Changing Idea, SXSW & Cannes Lions Winner)',
//     active: false,
//     content: 'A completar',
//   },
//   {
//     id: 3,
//     title: 'Huerta en Sede Ayax',
//     subtitle: '(Sustentabilidad & Economía Naranja)',
//     active: false,
//     content: 'A completar',
//   },
// ];

export default function Collapsable() {
  const [collapsableItems, setCollapsableItems] = useState(false);
  const toggleCollapsable = (itemId, btnId) => () => {
    const node = document.getElementById(itemId);
    const btnNode = document.getElementById(btnId);
    setCollapsableItems(!collapsableItems);
    if (collapsableItems) {
      node.classList.add('expanded');
      btnNode.innerText = '-';
    } else {
      node.classList.remove('expanded');
      btnNode.innerText = '+';
    }
  };
  const hy = require.context('../assets/images/Hy', true);
  const huerta = require.context('../assets/images/Huerta', true);
  const hybrid = require.context('../assets/images/Hybrid', true);

  return (
    <ScrollAnimation animateIn="animate__fadeIn" scrollableParentSelector="#about-modal" offset="5">
      <div>
        <div
          style={{
            borderBottom: 'black solid 1px', paddingLeft: 0, paddingRight: 0, position: 'relative',
          }}
          className="column"
        >
          <button
            id="a"
            className="collapsable-toggle"
            type="button"
            onClick={toggleCollapsable(1, 'a')}
          >
            -
          </button>
          <h4 className="pd-x" style={{ fontSize: '2rem', color: 'black' }}>Innovación al servicio del planeta</h4>
          <h5 className="pd-x" style={{ color: 'black' }}>En Ayax/Toyota tenemos un verdadero compromiso con el medio ambiente, por eso usamos la tecnología para protegerlo.</h5>
          <div id="1" className="collapsable-content expanded">
            <div>
              <p>
                Los nuevos coches híbridos y eléctricos deben
                emitir un sonido artificial como medida de seguridad.
                Nos preguntamos, ¿y si los sistemas de alerta acústica
                para vehículos pudieran contribuir al medio ambiente
                además de ser funcionales?
              </p>
              <br />
              <p>
                Una iniciativa que impacta de forma positiva en el entorno
                para crear un mejor futuro. Conocela en detalle en
                <a href="http:s//www.thehyproject.com"> www.thehyproject.com.</a>
              </p>
              <div className="collapsable-content-img">
                <img style={{ margin: '4px' }} src={hy('./aicp.svg')} alt="nombre" />
                <img style={{ margin: '4px' }} src={hy('./wci.svg')} alt="nombre" />
                <img style={{ margin: '4px' }} src={hy('./sxsw.png')} alt="nombre" />
                <img style={{ margin: '4px' }} src={hy('./nyf.svg')} alt="nombre" />
                <img style={{ margin: '4px' }} src={hy('./one-show.svg')} alt="nombre" />
                <img style={{ margin: '4px' }} src={hy('./cannes.svg')} alt="nombre" />
                <img style={{ margin: '4px' }} src={hy('./moma.svg')} alt="nombre" />
              </div>
              <div id="videoContainer" style={{ padding: '4px' }}>
                {/* <iframe title="Huerta Cursio" height="660px" width="100%"
                id="videoAboutModalcollapsable" src="" frameBorder="0" allowFullScreen /> */}
                <iframe width="100%" id="videoAboutModalcollapsable" height="660px" src="https://www.youtube.com/embed/bzCuN5SVMQ8" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                {/* <video loop muted controls="true" id="videoAboutModal" className="animate-up">
                  <source src={EchoVideo} type="video/mp4" />
                </video> */}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            borderBottom: 'black solid 1px', paddingLeft: 0, paddingRight: 0, position: 'relative',
          }}
          className="column"
        >
          <button
            id="b"
            className="collapsable-toggle"
            type="button"
            onClick={toggleCollapsable(2, 'b')}
          >
            +
            {/* {item.active ? '-' : '+'} */}
          </button>
          <h4 className="pd-x" style={{ fontSize: '2rem', color: 'black' }}>Huerta Ayax: Del sueño a la realidad</h4>
          <h5 className="pd-x" style={{ color: 'black' }}>Una inversión sustentable en pro de la economía circular, que ayuda al planeta.</h5>
          <div id="2" className="collapsable-content">
            <div>
              <p>
                El sueño de la huerta propia en el techo de nuestro
                centro de operaciones se transformó en una realidad.
              </p>
              <br />
              <p>
                Se trata de un espacio de esparcimiento para nuestros
                clientes y colaboradores, que además genera alrededor de 400 kg de alimentos.
              </p>
              <br />
              <p>
                Este excedente alimenticio es el principal insumo de restaurantes
                de la zona, para luego convertirse en el alimento de miles de uruguayos,
                completando así el ciclo de la economía circular.
              </p>
              <div>
                <img style={{ margin: '4px' }} width="100%" src={huerta('./937A2268.jpg')} alt="nombre" />
              </div>
              <div id="videoContainer" style={{ padding: '4px' }}>
                <video width="100%" height="70%" controls muted>
                  <source src={VideoHuerta} type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            borderBottom: 'black solid 1px', paddingLeft: 0, paddingRight: 0, position: 'relative',
          }}
          className="column"
        >
          <button
            id="c"
            className="collapsable-toggle"
            type="button"
            onClick={toggleCollapsable(3, 'c')}
          >
            +
            {/* {item.active ? '-' : '+'} */}
          </button>
          <h4 className="pd-x" style={{ fontSize: '2rem', color: 'black' }}>Bienvenidos al Mundo de Híbrido</h4>
          <h5 className="pd-x" style={{ color: 'black' }}>La marca que menos contamina por km recorrido.</h5>
          <div id="3" className="collapsable-content">
            <div>
              <p>
                Toyota es una marca líder a nivel mundial en penetración de vehículos híbridos.
                Tanto es así, que el 73% de todos los vehículos híbridos de nuestro país son Toyota.
                Y ese número asciende al 90% entre los vehículos de pasajeros de la marca.
              </p>
              <br />
              <p>
                Esto ha permitido que, tan solo considerando Uruguay,
                Toyota haya evitado emitir más de 4 millones de kilogramos
                de CO2 a la atmósfera, en un compromiso sustentable verdadero.
              </p>
              <br />
              <p>
                Ahorro de combustible, seguridad y diseño, mientras cuidás el medio ambiente.
              </p>
              <div>
                <img style={{ margin: '4px' }} width="100%" src={hybrid('./LineaHybrid.png')} alt="nombre" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ScrollAnimation>
  );
}
