/* eslint-disable react/jsx-props-no-spreading,react/prop-types */

import * as React from 'react';
import {
  useEffect, useMemo, useState, useCallback,
} from 'react';
import ReactMapGL, { Layer, Source } from 'react-map-gl';
import { styleLayer } from './mapStyle';
import updateWeek from '../helpers/utils';
import useWindowSize from './windowSize';
import WeeklyValuesLocal from '../assets/data/weekValues.json';

export default function Map(props) {
  // eslint-disable-next-line
  const {selectedWeek, selectedValue, startDate, endDate } = props;
  const [geoData, setgeoData] = useState(null);
  const [initDateApi, setInitDateApi] = useState('2022-03-01');
  const [endDateApi, setEndDateApi] = useState('2022-03-01');
  const [weeklyValues, setWeeklyvalues] = useState(WeeklyValuesLocal);
  const [hoverInfo, setHoverInfo] = useState(undefined);
  useEffect(() => {
    const NowDate = new Date();
    let Day;
    let Month;
    const Year = NowDate.getFullYear();
    if (NowDate.getDate() <= 9) {
      // eslint-disable-next-line
      Day = "0"+NowDate.getDate();
    } else {
      Day = NowDate.getDate();
    }
    if (NowDate.getMonth() <= 8) {
      // eslint-disable-next-line
      Month = "0"+(NowDate.getMonth()+1);
    } else {
      Month = NowDate.getMonth() + 1;
    }
    // eslint-disable-next-line
    const InitDate = Year + "-" + Month + "-" + Day;
    setInitDateApi(InitDate);
    setEndDateApi(InitDate);
  }, []);
  useEffect(() => {
    let Day;
    let Month;
    const Year = startDate.getFullYear();
    if (startDate.getDate() <= 9) {
      // eslint-disable-next-line
      Day = "0"+startDate.getDate();
    } else {
      Day = startDate.getDate();
    }
    if (startDate.getMonth() <= 8) {
      // eslint-disable-next-line
      Month = "0"+(startDate.getMonth()+1);
    } else {
      Month = startDate.getMonth() + 1;
    }
    // eslint-disable-next-line
    const InitDate = Year + "-" + Month + "-" + Day;
    setInitDateApi(InitDate);
  }, [startDate]);
  useEffect(() => {
    if (endDate != null) {
      let Day;
      let Month;
      const Year = endDate.getFullYear();
      if (endDate.getDate() <= 9) {
      // eslint-disable-next-line
      Day = "0"+endDate.getDate();
      } else {
        Day = endDate.getDate();
      }
      if (endDate.getMonth() <= 8) {
      // eslint-disable-next-line
      Month = "0"+(endDate.getMonth()+1);
      } else {
        Month = endDate.getMonth() + 1;
      }
      // eslint-disable-next-line
    const InitDate = Year + "-" + Month + "-" + Day;
      setEndDateApi(InitDate);
    } else {
      setEndDateApi(initDateApi);
    }
  }, [endDate]);
  const [viewport, setViewport] = useState({
    width: '100%',
    height: '119vh',
    latitude: -34.900290,
    longitude: -56.177892,
    zoom: 13,
    pitch: 60, // pitch in degrees
    bearing: 50,
  });
  useEffect(() => {
    // eslint-disable-next-line
    //const URLAPI = "https://staging.boronstudio.com/focusapi/api/api.php?action=getTelemetryAndZonesInfoByDateRange&from="+initDateApi+"&to="+endDateApi+"";
    // eslint-disable-next-line
    //const URLAPI = `http://localhost/toyota/api/api.php?action=getTelemetryAndZonesInfoByDateRange&from=${initDateApi}&to=${endDateApi}`;
    // eslint-disable-next-line
    //const URLAPI = `http://23.239.16.82/api/api.php?action=getTelemetryAndZonesInfoByDateRange&from=${initDateApi}&to=${endDateApi}`;
    // eslint-disable-next-line
    const URLAPI = `https://echoindex.uy/api/api.php?action=getTelemetryAndZonesInfoByDateRange&from=${initDateApi}&to=${endDateApi}`;
    // eslint-disable-next-line
    fetch(URLAPI)
      .then((res) => res.json())
      // eslint-disable-next-line
      .then((response) => {
        // eslint-disable-next-line
        const weekvalues = new Array();
        // eslint-disable-next-line
        const cant = Object.values(response.data).length;
        // eslint-disable-next-line
        var objetos = 'aaa';
        // eslint-disable-next-line
        const values = null;
        // eslint-disable-next-line
        const nuevoDato = null;
        // eslint-disable-next-line
      for (var i=1;i<=cant; i++) {
          // eslint-disable-next-line
          response.data[i][0]['zoneId'] = i;
          // eslint-disable-next-line
          response.data[i][0]['week']  ='1092021';
          // eslint-disable-next-line
          weekvalues.push(response.data[i][0]);
        }
        // eslint-disable-next-line
        var nuevoJSemana=JSON.parse(JSON.stringify(weekvalues));
        // eslint-disable-next-line
        setWeeklyvalues(nuevoJSemana);
        setgeoData(response);
        setViewport({
          width: '100%',
          height: '119vh',
          latitude: response.init_map.lat,
          longitude: response.init_map.lng,
          zoom: 15, // estaba en 13
          pitch: 60, // pitch in degrees
          bearing: 50,
        });
      });
  }, [endDateApi]);
  function redrawMap() {
    setViewport({
      ...viewport,
      width: '100%',
      height: '119vh',
    });
  }
  const { windowWidth, windowHeight } = useWindowSize();

  // redraw if the map's parent container or window changes size
  useEffect(() => {
    redrawMap();
  }, [windowWidth, windowHeight]);

  const [paintLayer, setPaintLayer] = useState(null);

  useEffect(() => {
    const barColors = {
      'pm2.5': [[10, '#55FB89'], [15, '#AAF775'], [25, '#FFF361'], [37.5, '#FFB654'], [50, '#FF7848'], [75, '#FF3B3B']],
      pm10: [[50, '#55FB89'], [75, '#AAF775'], [100, '#FFF361'], [150, '#FFB654'], [225, '#FF7848'], [340, '#FF3B3B']],
      db: [[60, '#55FB89'], [75, '#AAF775'], [85, '#FFF361'], [100, '#FFB654'], [120, '#FF7848'], [140, '#FF3B3B']],
      uv: [[2240, '#55FB89'], [4482, '#AAF775'], [5976, '#FFF361'], [7096, '#FFB654'], [8216, '#FF7848'], [10000, '#FF3B3B']],
      voc: [[0.2, '#55FB89'], [3, '#AAF775'], [10, '#FFF361'], [20, '#FFB654'], [24, '#FF7848'], [26, '#FF3B3B']],
      co2: [[350, '#55FB89'], [400, '#AAF775'], [1000, '#FFF361'], [1500, '#FFB654'], [1999, '#FF7848'], [3000, '#FF3B3B']],
    };

    // TODO figure out animation
    // Grab selected value and modify map style.paint
    const animation = window.requestAnimationFrame(() => setPaintLayer({
      'fill-extrusion-color': {
        property: selectedValue.id,
        stops: barColors[selectedValue.id],
      },
      'fill-extrusion-height': [
        'interpolate',
        ['linear'],
        ['get', selectedValue.id],
        // stop pairs
        0, 0,
        100, 100,
        200, 200,
        300, 300,
        400, 400,
        500, 500,
      ],
    }));
    return () => window.cancelAnimationFrame(animation);
  }, [selectedValue]);

  // eslint-disable-next-line max-len
  const data = useMemo(() => geoData && updateWeek(geoData, weeklyValues), [[selectedValue]]);
  const onHover = useCallback((event) => {
    const {
      features,
      point: { x, y },
    } = event;
    const hoveredFeature = features && features[0];
    // prettier-ignore
    setHoverInfo(hoveredFeature && { feature: hoveredFeature, x, y });
  }, []);
  // Set Hover
  let vl;
  let bgColor;
  // let altData;
  if (hoverInfo !== undefined) {
    const vl2 = selectedValue.id;
    // const { geometry } = hoverInfo.feature.geometry;
    // const { properties } = hoverInfo.feature.properties
    // const altArrayData = {geometry,properties,type:"Feature"};
    // altData = {features:[altArrayData]};
    switch (vl2) {
      case 'pm2.5':
        vl = hoverInfo.feature.properties['pm2.5'];
        if (vl <= 10) {
          bgColor = '#55FB89';
        } else if (vl <= 15) {
          bgColor = '#AAF775';
        } else if (vl <= 25) {
          bgColor = '#FFF361';
        } else if (vl <= 37.5) {
          bgColor = '#FFB654';
        } else if (vl <= 50) {
          bgColor = '#FF7848';
        } else {
          bgColor = '#FF3B3B';
        }
        break;
      case 'pm10':
        vl = hoverInfo.feature.properties.pm10;
        if (vl <= 50) {
          bgColor = '#55FB89';
        } else if (vl <= 75) {
          bgColor = '#AAF775';
        } else if (vl <= 100) {
          bgColor = '#FFF361';
        } else if (vl <= 150) {
          bgColor = '#FFB654';
        } else if (vl <= 225) {
          bgColor = '#FF7848';
        } else {
          bgColor = '#FF3B3B';
        }
        break;
      case 'db':
        vl = hoverInfo.feature.properties.db;
        if (vl <= 60) {
          bgColor = '#55FB89';
        } else if (vl <= 75) {
          bgColor = '#AAF775';
        } else if (vl <= 85) {
          bgColor = '#FFF361';
        } else if (vl <= 100) {
          bgColor = '#FFB654';
        } else if (vl <= 120) {
          bgColor = '#FF7848';
        } else {
          bgColor = '#FF3B3B';
        }
        break;
      case 'uv':
        vl = hoverInfo.feature.properties.uv;
        if (vl <= 2240) {
          bgColor = '#55FB89';
        } else if (vl <= 4482) {
          bgColor = '#AAF775';
        } else if (vl <= 5976) {
          bgColor = '#FFF361';
        } else if (vl <= 7096) {
          bgColor = '#FFB654';
        } else if (vl <= 8216) {
          bgColor = '#FF7848';
        } else {
          bgColor = '#FF3B3B';
        }
        break;
      case 'voc':
        vl = hoverInfo.feature.properties.voc;
        if (vl <= 0.2) {
          bgColor = '#55FB89';
        } else if (vl <= 3) {
          bgColor = '#AAF775';
        } else if (vl <= 10) {
          bgColor = '#FFF361';
        } else if (vl <= 20) {
          bgColor = '#FFB654';
        } else if (vl <= 24) {
          bgColor = '#FF7848';
        } else {
          bgColor = '#FF3B3B';
        }
        break;
      case 'co2':
        vl = hoverInfo.feature.properties.co2;
        if (vl <= 350) {
          bgColor = '#55FB89';
        } else if (vl <= 400) {
          bgColor = '#AAF775';
        } else if (vl <= 1000) {
          bgColor = '#FFF361';
        } else if (vl <= 1500) {
          bgColor = '#FFB654';
        } else if (vl <= 1999) {
          bgColor = '#FF7848';
        } else {
          bgColor = '#FF3B3B';
        }
        break;
      default:
        break;
    }
  }
  // eslint-disable-next-line max-len
  return (
    <ReactMapGL
      {...viewport}
      mapboxAccessToken="pk.eyJ1IjoiZnJhbmtqcmFuZ2VsIiwiYSI6ImNrdDljMmhocDFhbWYzMnI1eDl2Y2lwYm0ifQ.Op0miDb3t-6zZG61Ai2Z2g"
      mapStyle="mapbox://styles/frankjrangel/cktylwl5e0r3a18p6ej97upu0"
      interactiveLayerIds={['data']}
      onMove={(evt) => setViewport(evt.viewport)}
      onMouseMove={onHover}
    >
      <Source type="geojson" data={data}>
        <Layer {...styleLayer} paint={paintLayer} />
      </Source>
      {hoverInfo && (
        <div className="tooltip" style={{ left: hoverInfo.x - 5, top: hoverInfo.y - 49, background: bgColor }}>
          <h1 style={{ color: '#000000' }}>
            {vl}
          </h1>
        </div>
      )}
    </ReactMapGL>
  );
}
